
var wipwrp = window.wipwrp || {};

wipwrp.domanda = (function($,mzr,doc,win){


	var	self				=	{};
	var step = 1;
	var _vars = {};
	var _beforeUnloadHandler = function(e){
        /**
         NOTA: Dovuto a nuove specifiche nei browser moderni non possiamo implementare il popup alert
         tramite l'evento beforeunload (https://www.chromestatus.com/feature/5349061406228480)
         Di consequenza verrà visualizzato l'alert di conferma standard di ogni browser, se la
         pagina corrente (domanda) venisse ricaricata/cambiata.
         **/
        e.preventDefault();
        e.returnValue='';
    };

	function cloneElements(array) {
		return Array.prototype.slice.apply(array).map(function (e) {
			return e.cloneNode(true);
		})
	}

	self.init = function(){
		_setParsley();
		_setSteps();
        _setSticky();
        if(_isDomandaPage()){
            _setVexExit();
        }
		wipwrp.commons.setInputParent('.control-group');
		wipwrp.utils.countLetters('.count-field');
		wipwrp.utils.checkLikeRadio();

		wipwrp.commons.relationField();
		wipwrp.commons.copyGroup();
		wipwrp.commons.datepicker();
		wipwrp.autocomplete.init();
		wipwrp.autocomplete.init({
			selector: ".autocomplete-prefisso",
			valueinfield: true
		});
		wipwrp.autocomplete.init({
			selector: ".autocomplete-provincia",
			valueinfield: true
		});

		wipwrp.commons.moneynumber('.moneynumber');
		wipwrp.commons.negativemoneynumber('.negativemoneynumber');
		wipwrp.utils.uppercaseControl('[data-textcontrol="uppercase"]');
		wipwrp.utils.lowercaseControl('[data-textcontrol="lowercase"]');
		wipwrp.utils.capitalizeControl('[data-textcontrol="capitalize"]');
        wipwrp.utils.ibanTransform('[data-texttransform="iban"]');

		wipwrp.accordion.init({
			multivisible: true
		});

		wipwrp.commons.checkCapByNation();

		var fotoStand = document.querySelector('#foto_stand');

		wipwrp.imageCropper.init(fotoStand, {
			inputFile: document.querySelector('input[name="foto_stand"]'),
			inputCropX: document.querySelector('input[name="foto_stand_crop_x"'),
            inputCropY: document.querySelector('input[name="foto_stand_crop_y"'),
            inputCropW: document.querySelector('input[name="foto_stand_crop_w"'),
            inputCropH: document.querySelector('input[name="foto_stand_crop_h"'),
            infoBtn: document.querySelector("#foto_stand_info_btn")
		});

		_vars.clonedPaymentOptions = cloneElements(document.querySelectorAll('#modalita_pagamento option'))


		wipwrp.commons.initCkEditor('#storiaattivita');
	};

	function _isDomandaPage(){
		var currentPage = document.querySelector('body');
		var dataPage = currentPage.dataset.page;
		return dataPage === 'domanda';
	}
	function _setVexExit(){
    _vars.alertExitAdmission = document.getElementById('exit-alert');

		var menuBar = document.querySelector('#masthead>nav');
        // window.addEventListener('beforeunload',_beforeUnloadHandler);
        if (menuBar) {
            menuBar.addEventListener('click',function(e){
                // Fermo!
                e.preventDefault();
                //Controllo se target ha un href non vuoto e non è undefined ("fiera" e "piattaforma digitale" non hanno href)
                if(e.target.href !=='' && e.target.href !== undefined ){
                    var href = e.target.href;
                    //apriamo l'alert di abbandono passandoli l'href per eseguire il flusso del link nel menu in caso di conferma
                    _openAbandonAlert(href);
					// se clicchiamo invece su "fiera" o "piattaforma digitale" passeremo lo slug invece dell'href
                } else {
					var slug = e.target.dataset.slug;
					_openAbandonAlert(slug);
				}
            });
		}
	}
	function _openAbandonAlert(goTo){
        if(!_vars.alertExitAdmission.classList.contains('open')){
            _vars.alertExitAdmission.classList.add('open');
		}

        var txtOkBtn = _vars.alertExitAdmission? _vars.alertExitAdmission.dataset.textOkBtn:'SI';
        var txtCancelBtn = _vars.alertExitAdmission? _vars.alertExitAdmission.dataset.textCancelBtn:'NO';
        vex.dialog.confirm({
            unsafeMessage:_vars.alertExitAdmission,
            showCloseButton:true,
            overlayClosesOnClick:false,
            buttons: [
                $.extend({},vex.dialog.buttons.YES,{
                    text: txtOkBtn,
                    className: 'btn-1 outline',
                }),
                $.extend({},vex.dialog.buttons.NO,{
                    text: txtCancelBtn,
                    className: 'btn-1 ',
                })
            ],
            callback: function(value){
                // Se clickato YES
                if(value){
                    window.removeEventListener('beforeunload',_beforeUnloadHandler);
                    window.location.href = goTo?goTo:'/';
                }
            }
        })
	}
    function _setSticky(){
        var el = document.getElementById('domanda_counter');
        if (el) {
            var elPos = window.pageYOffset + el.getBoundingClientRect().top;
            // var elHeight = (window.pageYOffset + el.getBoundingClientRect().top) - 40;
            var headHeight = document.getElementById('header_bar').clientHeight;
            var scrollDelta = elPos + headHeight;

            window.onscroll = scroll;
            function scroll () {

                var scrollTop = window.pageYOffset;

                if( scrollTop >= 2 ){
                    document.getElementById('form_domanda').style.marginTop = headHeight+"px";
                    el.classList.add('sticky');
                    el.style.marginTop= headHeight+"px";

                }
                else
                {
                    document.getElementById('form_domanda').style.marginTop = "0"
                    el.classList.remove('sticky');
                    el.style.marginTop= "0";

                }
            }
				}
    }


    /**
	* setto la situazione iniziale: posizione iniziale dove c'è .panel è ".current" - sovrascrivo con get
	* ?step=[NUMERO_STEP] aggiungo counter degli step in testa lancio funzioni di controllo
	*/



	function _setSteps(){
		var _counter = "";
		var _index;

		//_stepControl();

		window.location.hash = "#step1";
		step = 1;
		$('.panel.step').removeClass('current');
		$('.panel.step[data-step="'+step+'"]').addClass('current');
		_switchStepFunctions();

		window.onhashchange = _hashChange;

		$('.panel.step').each(function(i){
			_index = i+1;
			if($(this).hasClass('current')){
				_counter += '<li class="active">'+_index+'</li>';
			}else{
				_counter += '<li>'+_index+'</li>';
			}
		});
		$('#domanda_counter ul').append(_counter);

		_changeStep();
		_recapflow();
	}

	function _hashChange(){
		$('#domanda_counter ul li.active').removeClass('active').addClass('visited');
		$('.panel.step.current').removeClass('current').addClass('visited');
		$('#domanda_counter ul li:eq('+(step-1)+')').addClass('active');
		$('.panel.step:eq('+(step-1)+')').addClass('current');
		$("html, body").animate({ scrollTop: 0 }, "slow");
		_switchStepFunctions();
	}

	/**
	* gestione bottoni di navigazione (se vado avanti, passo per _validateStep())
	*/

	/* ppt = uteriore variabile di controllo per evitare click next compulsivi... */
	var ppt = true;

	function _changeStep(){
		$('.panel.step').on('click','.step-nav',function(e){
			e.preventDefault();
			if (ppt === true) {
				ppt = false;
				// AVANTI
				if($(this).hasClass('next')){
					if (_validateStep() === true) {
						step = parseInt(step,10);
						step += 1;
						window.location.hash = "#step"+step;
					}
				// TORNO AL RECAP (VISIBILE SOLO DOPO LA PRIMA VOLTA CHE ARRIVO ALLA FINE)
				}else if($(this).hasClass('torecap')){
					if (_validateStep() === true) {
                        step = $(this).data().tostep;
						console.log('recapt to:', step);
						window.location.hash = "#step"+step;
					}
				}else{
				// INDIETRO
					step = parseInt(step,10);
					step  -= 1;
					window.location.hash = "#step"+step;
				}
				setTimeout(function(){
					ppt = true;
				},1500);
			}

		});
	}

	function _switchStepFunctions(){
		var blockstep = parseInt($('.domanda.current').data('step'), 10);
		switch(blockstep) {
			case 1:


          			wipwrp.switcherTipoContratto.init();
                _setOldFields('#wrap_step_01','fatture_via_email');
                _controllaNazione('nazione_azienda_carta_value','provincia_azienda_carta','controlgroup_provincia_carta','controlgroup_nazione_carta','cap_azienda_carta');
                $('#nazione_azienda_carta').on('blur',function(e){_controllaNazione('nazione_azienda_carta_value','provincia_azienda_carta','controlgroup_provincia_carta','controlgroup_nazione_carta','cap_azienda_carta');});
            break;

			case 3:

                var attivitaSvolte = [];

                $('[name*="attivitaSvolteAll[]"]').each(function(){
                    attivitaSvolte[$(this).val()] = $(this).attr('data-label');
                });

                _vars.attivitaSvolte = attivitaSvolte;

                $('#attivita_svolta').change(function(e){
                    _check_attivita_svolta();
                });
                $('#attivita_svolta-b').change(function(e){
                    _check_attivita_svolta();
                });
                // $('#attivita_svolta-c').change(function(e){
                //     _check_attivita_svolta();
                // });
				$('#biologico-y').change(function(e){
					_check_salone_bio();
				});
				$('#biologico-n').change(function(e){
					_check_salone_bio();
				});

				_verifyCheckedAccordion();
                _showMessageServiziAggiuntivi();
                _initRepeater();
			break;
			case 4:
				_check_ente_istituzione();
			break;
            case 5:
                $('[name="apparire_a_catalogo"][value="1"]').trigger('change');
            break;
			case 6:
            	setPayments();
                var $step6 = $('#wrap_step_06');
                //riferisco al campo hidden generato dalla funzione wipwrp.commons.moneynumber
                $step6.off('change','#acconto_pagamento_id');
                $step6.on('change','#acconto_pagamento_id',function(){
                    _gestioneRate($('#acconto_minimo_id').val(),$('#acconto_massimo_id').val(),$('#acconto_pagamento_id').val());
                });
                _checkPropostaGefiImport();
            break;
			case 7:
				//dico che sono arrivato
				$('#form_domanda').removeClass('not-all-filled');
				//riempio i campi
				_fillrows();
			break;
			case 9:
				$('#domanda_submit').click(
					function(e){
						console.log("Form Data: \n");
						$('#form_domanda').serializeArray().map(function (element) {
							console.log("["+ element.name +"] - ["+element.value+"]");
						})
						$('#form_domanda').submit();
					});
			break;
		}
	}

	function _checkPropostaGefiImport(){
		/* PropostaGefi true e imponibile 0 */
        var $step6 = $('#wrap_step_06');
        var $section = $('#section-pagamenti');
        var propostagefi = $section.data('propostagefi');
        var imponibile = $('#importo_totale').val();
        var condition = (propostagefi === 1 && parseInt(imponibile) === 0);
        (condition) ? $section.hide() : $section.show();

        $step6.on('change', '#importo_totale' ,function(){
            var imponibile = $('#importo_totale').val();
            var condition = (propostagefi === 1 && parseInt(imponibile) === 0);
			(condition) ? $section.hide() : $section.show();
        });
	}


	function _check_attivita_aziendale(settore){
		if(settore != '5'){
			$('#area_import_export').val('');
			$('#nazione_europea').val('');
			$('#paese_del_mondo').val('');
		}
	}

	function _check_salone_bio() {
		if($('#biologico-y').is(':checked')) {
			$('.biologico-notification').show();
		} else {
			$('.biologico-notification').hide();
		}
	}

	function _check_attivita_svolta(){
		var attivita_a = Number($("#attivita_svolta").val());
		var attivita_b = Number($("#attivita_svolta-b").val());
        // var attivita_c = Number($("#attivita_svolta-c").val());

		//se sono ristoratore, cambio costi al metro quadro

		// $('#mqrichiesti_stand').val(0);
		// $('#mqrichiesti_stand').blur();
		// $('#naziende_stand').val(0);
		// $('#naziende_stand').blur();

        var attivita_svolta_id;
        if(attivita_a === 1){
            attivita_svolta_id = 1;
        }else if(attivita_a === 4){
            attivita_svolta_id = 4;
        }else if(attivita_b === 1){
            attivita_svolta_id = 11;
        }else if(attivita_b === 2){
            attivita_svolta_id = 12;
        }else if(attivita_b === 3){
            attivita_svolta_id = 13;
        }else if(attivita_b === 4){
            attivita_svolta_id = 2;
        }
        $("input[name=attivita_svolta_id]").val(attivita_svolta_id);

		if(attivita_svolta_id === 12){
			var costo1LatoRistorazione = $('#hid_1latoLiberoRistorazione').val();
			var costo2LatiRistorazione = $('#hid_2latiLiberiRistorazione').val();
            var sconto1LatoRistorazione = $('#hid_sconto1LatoLiberoRistorazione').val();
            var sconto2LatiRistorazione = $('#hid_sconto2LatiLiberiRistorazione').val();
			$('#1Lato').data('costo_1lato',costo1LatoRistorazione);
			$('#2Lati').data('costo_2lati',costo2LatiRistorazione);
            $('#Sconto1Lato').data('sconto_1lato',sconto1LatoRistorazione);
            $('#Sconto2Lati').data('sconto_2lati',sconto2LatiRistorazione);
		}else if(attivita_svolta_id === 13){
            var costo1Lato50e50 = (Number($('#hid_1latoLiberoNoRistorazione').val()) + Number($('#hid_1latoLiberoRistorazione').val())) / 2;
            var costo2Lati50e50 = (Number($('#hid_2latiLiberiNoRistorazione').val()) + Number($('#hid_2latiLiberiRistorazione').val())) / 2;
            var sconto1Lato50e50 = (Number($('#hid_sconto1LatoLiberoNoRistorazione').val()) + Number($('#hid_sconto1LatoLiberoRistorazione').val())) / 2;
            var sconto2Lati50e50 = (Number($('#hid_sconto2LatiLiberiNoRistorazione').val()) + Number($('#hid_sconto2LatiLiberiRistorazione').val())) / 2;
            $('#1Lato').data('costo_1lato',costo1Lato50e50);
            $('#2Lati').data('costo_2lati',costo2Lati50e50);
            $('#Sconto1Lato').data('sconto_1lato',sconto1Lato50e50);
            $('#Sconto2Lati').data('sconto_2lati',sconto2Lati50e50);

		}else{
			//rimetto i costi vecchi
			var costo1LatoNoRistorazione = $('#hid_1latoLiberoNoRistorazione').val();
			var costo2LatiNoRistorazione = $('#hid_2latiLiberiNoRistorazione').val();
            var sconto1LatoNoRistorazione = $('#hid_sconto1LatoLiberoNoRistorazione').val();
            var sconto2LatiNoRistorazione = $('#hid_sconto2LatiLiberiNoRistorazione').val();
			$('#1Lato').data('costo_1lato',costo1LatoNoRistorazione);
			$('#2Lati').data('costo_2lati',costo2LatiNoRistorazione);
            $('#Sconto1Lato').data('sconto_1lato',sconto1LatoNoRistorazione);
            $('#Sconto2Lati').data('sconto_2lati',sconto2LatiNoRistorazione);
		}


        //TODO selettore attività vendita alimentari. cambiare valore dopo aggiornamento valori select relativa
        if (attivita_a === 14) {
            $('.attivita_svolta-b').show();
            $('#attivita_svolta-b').prop('required', true);
        } else {
            attivita_b = 0;
            // attivita_c = 0;
            $('#attivita_svolta-b').val(0);
            // $('#attivita_svolta-c').val(0);
            $('.attivita_svolta-b').hide();
            $('#attivita_svolta-b').prop('required', false);
            // $('.attivita_svolta-c').hide();
            // $('#attivita_svolta-c').prop('required', false);
        }

        // handle salone biologico
        $('#biologico-y').prop('checked', false);
        $('#biologico-n').prop('checked', false);
		$('.biologico-notification').hide();
        if (attivita_a === 1 || attivita_a === 4 || attivita_a === 14) {
			$('.biologico').show();
			$('#biologico-y').prop('required', true);
		} else {
			$('.biologico').hide();
			$('#biologico-y').prop('required', false);
		}

        // if (attivita_b === 3) {
		// 	$('.attivita_svolta-c').show();
        //     $('#attivita_svolta-c').prop('required', true);
        // } else {
        //     attivita_c = 0;
        //     $('#attivita_svolta-c').val(null);
        //     $('.attivita_svolta-c').hide();
        //     $('#attivita_svolta-c').prop('required', false);
        // }

		if (attivita_b === 2) {
			$('.attivita_svolta-d').show();
		}else {
			$('.attivita_svolta-d').hide();
		}

	}

	function _check_area_import_export(area){
		$('#nazione_europea').val('');
		$('#paese_del_mondo').val('');
	}


    function _initRepeater(){
        $('.repeater').repeater({
            isFirstItemUndeletable: true
        })
	}

	function _verifyCheckedAccordion(){
		$('#merceologia_group .accordion-content li.active').each(function(){
			var _content = $(this).parents('.accordion-content');
			var _item = $(this).parents('.accordion-item');
			var _trigger = _item.find('.accordion-trigger');
			//console.log(_item, _trigger);
			_content.slideDown();
			_item.addClass('active');
			_trigger.addClass('active');
		});
	}

	function _check_ente_istituzione() {
		if (Number($('#attivita_azienda').val()) === 4) {
			$('#step4-prodotti').hide();
			$('[name="attivita_istituzioni[]"]').prop('required', true);
			$('#step4-no-prodotti').show();
		} else {
			$('#step4-no-prodotti').hide();
			$('[name="attivita_istituzioni[]"]').prop('required', false);
			$('#step4-prodotti').show();
		}
	}

	function _controllaNazione(nazione_nascosta,provincia_visibile,controlgroup_provincia,controlgroup_nazione){
		if($('#'+nazione_nascosta).val()!='ITA'){
			$('#'+provincia_visibile).removeAttr('required');
			$('#'+controlgroup_provincia).slideUp();
			$('#'+controlgroup_nazione).removeClass('col_3').addClass('col_6');
		}else{
			// ECCEZIONE: PROVINCIA é SEMPRE OBBLIGATORIA SE ITA TRANNE IN CATALOGO
			if (controlgroup_nazione != 'controlgroup_nazione_catalogo') {
				$('#'+provincia_visibile).attr('required','');
			}
			$('#'+controlgroup_provincia).slideDown();
			$('#'+controlgroup_nazione).removeClass('col_6').addClass('col_3');
		}
	}
	// DUPLICO LA PRECEDENTE FUNZIONE IN RAPPRESENTATE.js DOVE HO LA RIPRESA DEI DATI CATALOGO
	// DUPLICO PERCHè potrebbe cambiare l'html


	function setPayments(){
		// Setto le variabili per le varie casistiche (ente pubblico, esente iva...)
		_vars.isEntePubblico = ($('#attivita_azienda option:selected').val() === '4') && ($('[name="nazione_azienda_customiso"]').val() === 'ITA');
		_vars.importoIscrizioneTitolare = Number($('#hid_importo_totale_titolare').val());
		_vars.importo_singola_azienda_rappresentata = Number($('#hid_importo_singola_azienda_rappresentata').val()).toFixed(2);
		_vars.esente_iva = $('#hid_esente_iva').val();
		_vars.aliquota_iva = $('#hid_aliquota_iva').val();
		_vars.propostaCommerciale = ($('#PropostaCommerciale').val() === '1') ? true : false;
		_vars.importo_singolo_servizi_aggiuntivi = $('#hid_importo_singolo_servizi_aggiuntivi').val();

		// check proposta commerciale
		if (_vars.propostaCommerciale) {
			$('#propostacommerciale_input').blur();
            $('#propostacommerciale_input').on('change', function(){
                if($('#propostacommerciale_input_id').val() === 0){
                  console.log('nn obbligarorio');
                  if($('#modalita_pagamento').length>0 && $('#modalita_pagamento').prop('visible')!='none')
                    $('#modalita_pagamento').prop('required', false);
                  if($('#forma_pagamento').length>0 && $('#forma_pagamento').prop('visible')!='none')
                    $('#forma_pagamento').prop('required', false);
                  if($('#importo_rata').length>0 && $('#importo_rata').prop('visible')!='none')
                    $('#importo_rata').prop('required', false);
                  if($('#importo_saldo').length>0 && $('#importo_saldo').prop('visible')!='none')
                    $('#importo_saldo').prop('required', false);
                  if($('#swift').length>0 && $('#swift').prop('visible')!='none')
                    $('#swift').prop('required', false);
                  if($('#bancabonifico_pagamento').length>0 && $('#bancabonifico_pagamento').prop('visible')!='none')
                    $('#bancabonifico_pagamento').prop('required', false);
                  if($('#nrassegno_pagamento').length>0 && $('#nrassegno_pagamento').prop('visible')!='none')
                    $('#nrassegno_pagamento').prop('required', false);
                  if($('#bancaassegno_pagamento').length>0 && $('#bancaassegno_pagamento').prop('visible')!='none')
                    $('#bancaassegno_pagamento').prop('required', false);
                }else{
                  if($('#modalita_pagamento').length>0 && $('#modalita_pagamento').prop('visible')!='none')
                    $('#modalita_pagamento').prop('required', true);
                  if($('#forma_pagamento').length>0 && $('#forma_pagamento').prop('visible')!='none')
                    $('#forma_pagamento').prop('required', true);
                  if($('#importo_rata').length>0 && $('#importo_rata').prop('visible')!='none')
                    $('#importo_rata').prop('required', true);
                  if($('#importo_saldo').length>0 && $('#importo_saldo').prop('visible')!='none')
                    $('#importo_saldo').prop('required', true);
                  if($('#swift').length>0 && $('#swift').prop('visible')!='none')
                    $('#swift').prop('required', true);
                  if($('#bancabonifico_pagamento').length>0 && $('#bancabonifico_pagamento').prop('visible')!='none')
                    $('#bancabonifico_pagamento').prop('required', true);
                  if($('#nrassegno_pagamento').length>0 && $('#nrassegno_pagamento').prop('visible')!='none')
                    $('#nrassegno_pagamento').prop('required', true);
                  if($('#bancaassegno_pagamento').length>0 && $('#bancaassegno_pagamento').prop('visible')!='none')
                    $('#bancaassegno_pagamento').prop('required', true);
                }
            });
			// reset parsley
			// $('#propostacommerciale_input_id').attr('required',true).attr('disabled',false).parent().show();
			// $('form#form_domanda').parsley().destroy();
			// _setParsley();





			$('#propostacommerciale_input_id').on('change', function() {
                _calcolaPropostaCommerciale();
			});

            $('#mqrichiesti_stand').off("change");
            $('#mqrichiesti_stand').on("change", function(e){
                _calcolaPropostaCommerciale();
            });
            $('#latiliberi_stand').change(function(){
                _calcolaPropostaCommerciale();
            });

            $('#naziende_stand').off("change");
            $('#naziende_stand').on("change", function(e){
				_checkNumeroAziende();
                _calcolaPropostaCommerciale();
            });
			_calcolaPropostaCommerciale();
		} else {
			// reset parsley
			// $('form#form_domanda').parsley().destroy();
			// $('#propostacommerciale_input_id').attr('required',false).attr('disabled',true).parent().hide();
			// _setParsley();

			$('#mqrichiesti_stand').off("change");
			$('#mqrichiesti_stand').on("change", function(e){
				_calcolaTotaliDomanda();
			});
			$('#latiliberi_stand').change(function(){
				_calcolaTotaliDomanda();
			});

			$('#naziende_stand').off("change");
			$('#naziende_stand').on("change", function(e){

				_calcolaTotaliDomanda();
				_checkNumeroAziende();
			});
		}
		if ($('#mqrichiesti_stand').val() !== '') {
			$('#mqrichiesti_stand').trigger('change');
		}
	}
	function _calcolaTotaliDomanda() {
		_vars.importoMQRichiesti = $('#mqrichiesti_stand').val();
		if(isNaN(_vars.importoMQRichiesti) || _vars.importoMQRichiesti < 0 || _vars.importoMQRichiesti===''){
			_vars.importoMQRichiesti = 0;
		}

		var nlati = $('#latiliberi_stand').find(":selected").val();
		if(nlati == 1){
			_vars.costo_lati_liberi = $('#latiliberi_stand').find(":selected").data('costo_1lato');
            _vars.sconto_lati_liberi = $('#Sconto1Lato').data('sconto_1lato');
		}else{
			_vars.costo_lati_liberi = $('#latiliberi_stand').find(":selected").data('costo_2lati');
            _vars.sconto_lati_liberi = $('#Sconto2Lati').data('sconto_2lati');
		}
		_vars.costo_lati_liberi = Number(_vars.costo_lati_liberi).toFixed(2);
        _vars.sconto_lati_liberi = Number(_vars.sconto_lati_liberi).toFixed(2);
		_vars.n_aziende_rapp = $('#naziende_stand').val();
		if(isNaN(_vars.n_aziende_rapp) || _vars.n_aziende_rapp < 0 || _vars.n_aziende_rapp ===''){
			_vars.n_aziende_rapp = 0;
		}
		_vars.n_aziende_rapp = Number(_vars.n_aziende_rapp);
		// update fields
		$('#importo_titolare').val(_vars.importoIscrizioneTitolare).blur();
		$('#importo_totale_aziende_rappresentate').val(_vars.importo_singola_azienda_rappresentata * _vars.n_aziende_rapp).blur();
		$('#importo_totale_servizi_aggiuntivi').val(_vars.importo_singolo_servizi_aggiuntivi * (_vars.n_aziende_rapp + 1)).blur();//+1 è per mettere un servizio base per la titolare
		$('#importo_mq_richiesti').val((_vars.costo_lati_liberi - _vars.sconto_lati_liberi)* _vars.importoMQRichiesti).blur();

		updateTotale();
	}
    function _checkNumeroAziende() {
        _vars.nrAziendeCollettiva = $('#naziende_stand').val();
        if(isNaN(_vars.nrAziendeCollettiva) || _vars.nrAziendeCollettiva < 0 || _vars.nrAziendeCollettiva===''){
            _vars.nrAziendeCollettiva = 0;
        }

        if( _vars.nrAziendeCollettiva == 1){
            $('#naziende_row').removeClass('hidden');
        }else{
            $('#naziende_row').addClass('hidden');
        }
    }

	function _calcolaPropostaCommerciale() {
		_vars.propostaCommercialeVal = Number($('#propostacommerciale_input_id').val());
        _vars.n_aziende_rapp = $('#naziende_stand').val();
        if(isNaN(_vars.n_aziende_rapp) || _vars.n_aziende_rapp < 0 || _vars.n_aziende_rapp ===''){
            _vars.n_aziende_rapp = 0;
        }
		_vars.n_aziende_rapp = Number(_vars.n_aziende_rapp);

		if (_vars.propostaCommercialeVal < _vars.importoIscrizioneTitolare) {
			_vars.importoMQRichiesti = -Math.abs(_vars.importoMQRichiesti);
		}
        var quotaCaseRappresentate = _vars.importo_singola_azienda_rappresentata * _vars.n_aziende_rapp;
		var quotaServiziAggiuntivi = _vars.importo_singolo_servizi_aggiuntivi * (_vars.n_aziende_rapp + 1);//+1 è per mettere un servizio base per la titolare

        _vars.importoMQRichiesti = _vars.propostaCommercialeVal - _vars.importoIscrizioneTitolare - quotaCaseRappresentate - quotaServiziAggiuntivi;

        // update fields

        $('#importo_titolare').val(_vars.importoIscrizioneTitolare).blur();
        $('#importo_totale_aziende_rappresentate').val(quotaCaseRappresentate).blur();
        $('#importo_totale_servizi_aggiuntivi').val(quotaServiziAggiuntivi).blur();
        $('#importo_mq_richiesti').val(_vars.importoMQRichiesti).blur();

		updateTotale();
	}

	function updateTotale() {
		if (_vars.propostaCommerciale) {
			_vars.imponibile = _vars.propostaCommercialeVal;
		} else {
			_vars.imponibile = _vars.importoIscrizioneTitolare + Number((_vars.importo_singola_azienda_rappresentata * _vars.n_aziende_rapp)) + Number(((_vars.costo_lati_liberi - _vars.sconto_lati_liberi) * _vars.importoMQRichiesti)) + Number(_vars.importo_singolo_servizi_aggiuntivi * (_vars.n_aziende_rapp+1));
		}
		// calcolo imponibile
		$('#importo_imponibile').val(_vars.imponibile).blur();
		// calcolo iva
		_vars._iva = 0;
		if(_vars.esente_iva=='0' || _vars.esente_iva==='') {
			_vars._iva = (Number(_vars.imponibile)/100*Number(_vars.aliquota_iva)).toFixed(2);
		}
		$('#importo_iva').val(_vars._iva).blur();
		// calcolo totale
		var _totale = (Number(_vars.imponibile) + Number(_vars._iva)).toFixed(2);
		$('#importo_totale').val(_totale).blur();
        $('#importo_totale').change();

		_verificoPagamenti();

		// if imponibile < 1500, hide finanziamento
		var select = $("#modalita_pagamento");
		var options = [];
		if (_vars.imponibile < 1500) {
			options = cloneElements(_vars.clonedPaymentOptions.filter(function (elem) {
				return elem.value !== 'finanziamento';
			}));
		} else {
			options = cloneElements(_vars.clonedPaymentOptions)
		}
		select.empty().append(options)

		// if is ente pubblico set payment mode to fifty
		if (_vars.isEntePubblico) {
			$('.section-pagamenti').addClass('hidden');
			$('div[data-related="bonifico"] .standard-notification').hide();
			$('div[data-related="bonifico"] .ente-pubblico').show();

			$('form#form_domanda').parsley().destroy();
			$('#iban').removeAttr('data-parsley-iban');
			$('#swift').removeAttr('data-parsley-swift');
			$('#iban,#swift,#bancabonifico_pagamento').attr('required',false);
			_setParsley();

			if ($("#wrap_step_06 #modalita_pagamento option[value='tenfourtyfifty']").length > 0) {
				$('#wrap_step_06 #modalita_pagamento').val('tenfourtyfifty').change();
			}else if ($("#wrap_step_06 #modalita_pagamento option[value='twentyfive']").length > 0) {
				$('#wrap_step_06 #modalita_pagamento').val('twentyfive').change();
			}else if ($("#wrap_step_06 #modalita_pagamento option[value='fifty']").length > 0) {
				$('#wrap_step_06 #modalita_pagamento').val('fifty').change();
			}else{
				$('#wrap_step_06 #modalita_pagamento').val('full').change();
			}
			$('#forma_pagamento').val('bonifico').change();
		} else {
			$('form#form_domanda').parsley().destroy();
			$('#iban').attr({
				'data-parsley-iban': '',
				'required': true
			});
			$('#swift').attr({
				'data-parsley-swift': '',
				'required': true
			});
			_setParsley();
			$('.section-pagamenti').removeClass('hidden');
			$('div[data-related="bonifico"] .ente-pubblico').hide();
			$('div[data-related="bonifico"] .standard-notification').show();
		}
	}

	function _verificoPagamenti(){
		$('#modalita_pagamento').change();
		$('#acconto_pagamento').change();
		_pagamento_e_acconto();
	}

	function _pagamento_e_acconto(){
		_vars.isSepa = $('#isSepa').val();
		var $accontoPagamento = $('#acconto_pagamento');
		var $accontoPagamentoId = $('#acconto_pagamento_id');

		var _sceltaprecedente = "";
		var _totale,_acconto_perc,_percmin,_accontominimo,_accontomassimo = 0;

		$('#wrap_step_06').off('change','#modalita_pagamento');
		$('#wrap_step_06').on('change','#modalita_pagamento',function(){

			_totale = $('#importo_totale_id').val();
			_acconto_perc = $('#percentuale_acconto_minimo').val();
			_percmin = _acconto_perc/100;
			_accontominimo = parseFloat((_totale*_percmin),10);
			_accontomassimo = _totale*0.9;
			if(_acconto_perc==100) {
				_accontomassimo= _accontominimo;
			}

			//sono campi che mi servono solo per valorizzare le informative con il numero formalizzato
			$('#acconto_minimo').val(_accontominimo.toFixed(2)).blur();
			$('#acconto_massimo').val(_accontomassimo.toFixed(2)).blur();

			//azzero select modalità pagamento acconto
			$('#forma_pagamento').val('').change();

			ppt = true; // vedi righe 94 e controllo compilazione campo acconto nel caso di rata

			$('#finanziamento-40k-alert').css('display', 'none')
			$('[data-finanziamento-40k-hide]').css('display', 'block')
			switch($(this).val()) {
				case "twentyfive":
					/* se è pagamento 25-25-50 */
					_accontominimo = parseFloat(_totale/4);
					$accontoPagamento.attr('readonly',true).val(_accontominimo.toFixed(2)).blur();

					$accontoPagamentoId.parents('.control-group').removeClass('pseudo-error');

					//svuoto tabella rate
					_eliminaLabelsTabellaRate();
					_eliminaValoriTabellaRate();
				break;
				case "tenfourtyfifty":
					/* se è pagamento 20-30-50 */
					_accontominimo = parseFloat(_totale/5);
					$accontoPagamento.attr('readonly',true).val(_accontominimo.toFixed(2)).blur();

					$accontoPagamentoId.parents('.control-group').removeClass('pseudo-error');

					//svuoto tabella rate
					_eliminaLabelsTabellaRate();
					_eliminaValoriTabellaRate();
				break;
				case "fifty":
					/* se è pagamento 50%-50% */
					_accontominimo = parseFloat(_totale/2);
					$accontoPagamento.attr('readonly',true).val(_accontominimo.toFixed(2)).blur();

					$accontoPagamentoId.parents('.control-group').removeClass('pseudo-error');

					//svuoto tabella rate
					_eliminaLabelsTabellaRate();
					_eliminaValoriTabellaRate();
				break;
				case "finanziamento":
					/* se è pagamento finanziamento */
					_accontominimo= Math.round(_totale / 1000.0)*100;
					$('#acconto_minimo').val(_accontominimo.toFixed(2)).blur();
					$accontoPagamento.attr('readonly',true).val(_accontominimo.toFixed(2)).blur();

					$('#group_iban').removeClass('novalidate hidden');
					$accontoPagamentoId.parents('.control-group').removeClass('pseudo-error');
					//svuoto tabella rate
					_eliminaLabelsTabellaRate();
					_eliminaValoriTabellaRate();
					// mostro alert se importo >= 40000 (iva inc)
					if (_vars.imponibile >= 32768) {
						requestAnimationFrame(() => {
							$('#finanziamento-40k-alert').css('display', 'block')
							$('[data-finanziamento-40k-hide]').css('display', 'none')

						})
					}
				break;
				/* se è una rata */
				case "rateSdd":
					if (_sceltaprecedente != 'rateCarta') {
						$accontoPagamento.attr('readonly',true).val(_accontominimo.toFixed(2)).blur();
                        $('#group_iban').removeClass('novalidate hidden');
						//valorizzo i .label-acconto-min e .label-acconto-max che ci sono in giro nelle informative
						//svuoto tabella rate
						_eliminaLabelsTabellaRate();
						_eliminaValoriTabellaRate();
					}
					_gestioneRate(_accontominimo,_accontomassimo,$accontoPagamentoId.val());
				break;
				case "rateCarta":
					if (_sceltaprecedente != 'rateSdd') {
						$accontoPagamento.attr('readonly',true).val(_accontominimo.toFixed(2)).blur();
						//svuoto tabella rate
						_eliminaLabelsTabellaRate();
						_eliminaValoriTabellaRate();
					}
					_gestioneRate(_accontominimo,_accontomassimo,$accontoPagamentoId.val());
					//se pago con carta l'acconto si può pagare solo con carta
					$('#forma_pagamento').val('carta').change();
				break;
				case "full":
					$accontoPagamento.attr('readonly',true).val(Number(_totale)).blur();
					$('#acconto_minimo').val(_totale).blur();
					checkRangeFull();
				break;
				default:
				//vuoto
			}
			$accontoPagamento.change();
			//mi segno la voce scelta per il prossimo giro
			_sceltaprecedente = $(this).val();
			_popolazioneValoriInformative();
		});
	}

	function checkRangeFull() {
        var dataDomanda = Date.now();
        var dataSaldoFull = new Date($('#data_saldo_full').val());
        var dataSaldoFull16gg = new Date($('#data_saldo_full_16gg').val());
        if(dataDomanda <= dataSaldoFull16gg){
            $('[data-relationgroup="tipologia"][data-related="full"] .notification.not-inrange').hide();
            $('[data-relationgroup="tipologia"][data-related="full"] .notification.inrange').hide();
            $('[data-relationgroup="tipologia"][data-related="full"] .notification.inrange_16days').show()
        }else if (dataDomanda <= dataSaldoFull) {
            $('[data-relationgroup="tipologia"][data-related="full"] .notification.inrange_16days').hide();
			$('[data-relationgroup="tipologia"][data-related="full"] .notification.not-inrange').hide();
			$('[data-relationgroup="tipologia"][data-related="full"] .notification.inrange').show();
		} else {
            $('[data-relationgroup="tipologia"][data-related="full"] .notification.inrange_16days').hide();
			$('[data-relationgroup="tipologia"][data-related="full"] .notification.inrange').hide();
			$('[data-relationgroup="tipologia"][data-related="full"] .notification.not-inrange').show();
		}
	}

	function _popolazioneValoriInformative(){

		if($('#modalita_pagamento').val()!==''){
			$('.warning_costo_stand_carta').html($('#importo_totale').val());

			var moneymin = wipwrp.commons.returnmoneynumber($('#acconto_pagamento_id').val());
			$('.warning_acconto_stand_min_value').html(moneymin);

			var moneyfifth = wipwrp.commons.returnmoneynumber($('#importo_totale_id').val()*0.5);
			$('.warning_acconto_stand_fifty_value').html(moneyfifth);

			var moneytwentyfive = wipwrp.commons.returnmoneynumber($('#importo_totale_id').val()*0.25);
			$('.warning_acconto_stand_twentyfive_value').html(moneytwentyfive);

			var moneytenfourtyfifty = wipwrp.commons.returnmoneynumber($('#importo_totale_id').val()*0.20);
			$('.warning_acconto_stand_tenfourtyfifty_value').html(moneytenfourtyfifty);

			var moneytenth = wipwrp.commons.returnmoneynumber($('#importo_totale_id').val()*0.1);
			$('.warning_acconto_stand_tenth_value').html(moneytenth);

			$('.warning_costo_stand_bonifico').html($('#importo_totale').val());
			$('.warning_acconto_stand').html($('#acconto_pagamento').val());
			$('#warning_costo_stand_assegno').html($('#importo_totale').val());
			$('#warning_acconto_stand_assegno').html($('#acconto_pagamento').val());
			$('.label-acconto-min').html($('#acconto_minimo').val());
			$('.label-acconto-max').html($('#acconto_massimo').val());
		}else{
			$('.warning_costo_stand_carta').html('');
			$('.warning_acconto_stand_min_value').html('');
			$('.warning_acconto_stand_twentyfive_value').html('');
			$('.warning_acconto_stand_tenfourtyfifty_value').html('');
			$('.warning_acconto_stand_fifty_value').html('');
			$('.warning_acconto_stand_tenth_value').html('');
			$('.warning_costo_stand_bonifico').html('');
			$('.warning_acconto_stand').html('');
			$('#warning_costo_stand_assegno').html('');
			$('#warning_acconto_stand_assegno').html('');
			$('.label-acconto-min').html('');
			$('.label-acconto-max').html('');
		}
	}

	function _gestioneRate(min,max,acconto){
		const paySelect = $("#modalita_pagamento").val();
		if(paySelect == "rateSdd" || paySelect == "rateCarta" ){
			let _accontominimo;
			let _accontomassimo;
			let _acconto;
			_costruzioneTabellaRate(paySelect);
			_calcoloRate();

			ppt = true; // vedi righe 94 e 376

			_acconto = parseFloat(parseFloat(acconto).toFixed(2));
			_accontominimo = parseFloat(parseFloat(min).toFixed(2));
			_accontomassimo = parseFloat(parseFloat(max).toFixed(2));

			if (_acconto >= _accontominimo && _acconto <= _accontomassimo) {

				$('#acconto_pagamento_id').parents('.control-group').removeClass('pseudo-error');
				_eliminaValoriTabellaRate();
				setTimeout(function(){
					_calcoloRate();
			 	},400);

			}else{
				//blocco possibilità di andare avanti - vedi riga 94
				ppt = false;
				//pulisco campi
				$('#importo_acconto').val(0).blur();
				$('#importo_rata').val(0).blur();
				$('#importo_saldo').val(0).blur();
				//svuoto tabella
				_eliminaValoriTabellaRate();
				$('#acconto_pagamento_id').parents('.control-group').addClass('pseudo-error');
			}
		}
	}



	function _eliminaLabelsTabellaRate(){
		$('#tabella_rate thead tr').html('') ;
	}
	function _eliminaValoriTabellaRate(){
		$('#tabella_rate tbody tr').html('');
	}

	function _costruzioneTabellaRate(paySelect)
	{
		/* labels tabella */
		var _n_rate = $('#n_rate').val();
		var _today = new Date();

		var _thisday = _today.getDate();
        var _payday = 20;
		var _thismonth = Number(_today.getMonth()) + 1;
		var _thisyear = _today.getFullYear();
		var _monthcounter = _thismonth;

		var _labelAcconto = $('#tabella_rate').data('labelacconto');
		var _labelRata = $('#tabella_rate').data('labelrata');
		var _labelSaldo = $('#tabella_rate').data('labelsaldo');

		//var _tr_labels = '<th>'+_labelAcconto+'<br>'+_thisday+" "+_montharray[_thismonth]+'</th>';
		var _tr_labels = '<th>'+_labelAcconto+'<br>'+_thisday+ "/" + _thismonth + "/" + _thisyear + '</th>';

		for (var i = 1; i <= _n_rate; i++) {
			_monthcounter+=1;
			var _theday = _thisday;
			if (_thisday > 28) {
				//verifico quanti giorni ha questo mese
				var _maxday = new Date(_thisyear, (_monthcounter+1), 0).getDate();
				if (_thisday > _maxday) {
					_theday = _maxday;
				}
			}
            //se SSD la data delle rate è _payday
            if(paySelect == "rateSdd" ){
                _theday = _payday;
            }
            _tr_labels += '<th>'+_labelRata+' '+i+'<br>'+_theday+"/"+_monthcounter+ "/" + _thisyear + '</th>';

        }
		_tr_labels += '<th>'+_labelSaldo+'</th>';
		$('#tabella_rate thead tr').html(_tr_labels);
	}


	function _calcoloRate(){
		/**
		* RATA = {[("IMPORTO TOT*80%") - "IMPORTO ACCONTO"] / "N. RATE INTERMEDIE"} arrot. a 2 cifre decimali per
		* difetto SALDO = "IMPORTO TOT" - "ACCONTO" - "Ʃ RATE"
		*/
		var _totale = $('#importo_totale_id').val();
		var _acconto = $('#acconto_pagamento_id').val();
		var _n_rate = $('#n_rate').val();

		var _rata = ((_totale *0.8) - _acconto) / _n_rate;
		//arrotondo per difetto al secondo decimale
		_rata = (parseInt(_rata*100)/100).toFixed(2);

		var _saldo = (_totale - _acconto - (_rata * _n_rate)).toFixed(2);

		$('#importo_rata').val(_rata).blur();
		$('#importo_saldo').val(_saldo).blur();

		/* valori tabella */
		var _n_rate = $('#n_rate').val();

		var _tr_numbers = '<td>€ '+$('#acconto_pagamento').val()+'</td>';

		for (var i = 1; i <= _n_rate; i++) {
			_tr_numbers += '<td>€ '+$('#importo_rata').val()+'</td>';
		}

		_tr_numbers += '<td>€ '+$('#importo_saldo').val()+'</td>';

		$('#tabella_rate tbody tr').html(_tr_numbers);

		_popolazioneValoriInformative();
	}


	function _showMessageServiziAggiuntivi(){
		$('.aggiuntivo-group input[type="radio"]').off('change');


		$('.aggiuntivo-group input[type="radio"]').on('change',function(){
			var _sometrue = $('.aggiuntivo-group input[type="radio"][value="1"]').length;
			$('.aggiuntivo-group input[type="radio"][value="1"]').each(function(){
				if($(this).is(':checked')){
					_sometrue--;
				}
			});

			if (_sometrue < $('.aggiuntivo-group input[type="radio"][value="1"]').length) {
				$('#aggiuntivo_info').slideDown();
			}else{
				$('#aggiuntivo_info').slideUp();
			}

		});

		$('.aggiuntivo-group input[type="radio"]').trigger('change');
	}

	/**
	* arrivato allo step 5 e 6 copio i dati che mi servono e -se flaggo "si" rendo i campi readonly
	*/
	function _setOldFields(block_id, radio_name){
		var _target, _value, _hiddentarget, _hiddenvalue;
		if ($('input[name="'+radio_name+'"][value="1"]').is(':checked')) {
			$(block_id+' .toggledata-area').slideDown();
			$(block_id+' [data-copytarget]').each(function(){
				if (!$(block_id).hasClass('visited')) {
					if ($(this).val() == "") {
						_target = $(this).attr('data-copytarget');
						_value = $(_target).val();
						if (_value != "")
						    $(this).val(_value).change().trigger('keyup');
						//if campo nascosto per value autocomplete
					}
					if ($('#'+$(this).attr('id')+'_value').length > 0) {
						if ($('#'+$(this).attr('id')+'_value').val() == "") {
							_hiddentarget = $(this).attr('data-copytarget')+'_value';
							_hiddenvalue = $(_hiddentarget).val();
                            if (_hiddenvalue != "")
							    $('#'+$(this).attr('id')+'_value').val(_hiddenvalue).change().trigger('keyup');
						}
					}
				}
			});
		}

		if($('input[name="'+radio_name+'"][value="1"]').is(':checked')){
			$('input[name="'+radio_name+'"][value="1"]').val('1').change().trigger('change');
		}
	}


	/**
	* torno indietro ai singoli step una volta arrivato in fondo
	*/

	function _recapflow(){
		$('#wrap_step_07').on('click','.edit-trigger',function(e){
			e.preventDefault();
			step = $(this).attr('data-editstep');
			window.location.hash = "#step"+step;
			$('#domanda_counter ul li.active').removeClass('active').addClass('visited');
			$('.panel.step.current').removeClass('current');

			$('#domanda_counter ul li:eq('+(step-1)+')').addClass('active');
			$('.panel.step[data-step="'+step+'"]').addClass('current');
		});
	}

	/**
	* ritorna true se validato
	*/
	function _validateStep(){
		var blockstep = parseInt($('.domanda.current').data('step'), 10);
		var parsleyValidation = false;
		if ($('form#form_domanda').parsley().validate({group: 'step'+blockstep, force: false}) === true){
			//check merceologie e nomi prodotto
			parsleyValidation = true;
		}
		var toCheckMerceologie = blockstep;
		if (blockstep === 4 && Number($('#attivita_azienda').val()) === 4) {
			toCheckMerceologie = false;
		}
		var merceologieCheck = wipwrp.utils.checkMerceologie(toCheckMerceologie);
		return parsleyValidation && merceologieCheck;
	}

	function _setParsley(){
		//istanzio anche all'inizio per controllare i campi in blur
		$('form#form_domanda').parsley();
		$('#domanda_submit').on('click', function (e) {
			e.preventDefault();
			if ($('form#form_domanda').parsley().validate() === true){
				$('.domanda_submit_class').hide();
				window.removeEventListener('beforeunload',_beforeUnloadHandler);
				return true;
			}else{
				//alert('Non hai compilato tutti i campi');
			}
		});
	}


	/**
	* RIEMPIO TUTTI I VALORI GIRANDO TUTTI ICAMPI DEL FORM
	*/

	function _fillrows(){
		/*
		if($(ID_VALORE_DI_RIFERIMENTO) !== ""){
			$(ID_RIGA .VALUE).text($(ID_VALORE_DI_RIFERIMENTO).val());
		}
		*/
		if(_vars.isEntePubblico) $('[data-hide="ente-pubblico"]').hide();

		// DATI GENERALI
		if($("#indirizzo_azienda").val() !== ""){
			$('#indirizzoazienda_row .value').text($('#indirizzo_azienda').val()+" "+$('#civico_azienda').val()+" "+", "+$('#cap_azienda').val()+" "+$('#citta_azienda').val()+" - "+$('#provincia_azienda').val()+" "+$('#nazione_azienda').val());
		}
		if($("#telefono_azienda").val() !== ""){
			$('#telefonoazienda_row .value').text($('#prefissotelefono_azienda').val()+" "+$('#telefono_azienda').val());
		}
        if($("#fax_azienda").val() !== ""){
            $('#faxazienda_row .value').text($('#prefissofax_azienda').val()+" "+$('#fax_azienda').val());
        }
		if($("#email_azienda").val() !== ""){
			$('#emailazienda_row .value').text($('#email_azienda').val());
		}
        if($("#website_azienda").val() !== ""){
            $('#websiteazienda_row .value').text($('#website_azienda').val());
        }

		// RAPPRESENTANTE LEGALE
		if($("#cognome_rappresentante").val() !== ""){
			$('#nomelegale_row .value').text($('#cognome_rappresentante').val()+" "+$('#nome_rappresentante').val());
		}
		if($("#telefono_rappresentante").val() !== ""){
			$('#telefonolegale_row .value').text($('#prefissotelefono_rappresentante').val()+" "+$('#telefono_rappresentante').val());
		}

		// RESPONSABILE STAND
		if($("#cognome_responsabile").val() !== ""){
			$('#nomeresponsabile_row .value').text($('#cognome_responsabile').val()+" "+$('#nome_responsabile').val());
		}
		if($("#telefono_responsabile").val() !== ""){
			$('#telefonoresponsabile_row .value').text($('#prefissotelefono_responsabile').val()+" "+$('#telefono_responsabile').val());
		}

		// RIFERIMENTO ECOMMERCE
		if($("#cognome_rifecommerce").val() !== ""){
			$('#nomerifecommerce_row .value').text($('#cognome_rifecommerce').val()+" "+$('#nome_rifecommerce').val());
		}
		if($("#telefono_rifecommerce").val() !== ""){
			$('#telefonorifecommerce_row .value').text($('#prefissotelefono_rifecommerce').val()+" "+$('#telefono_rifecommerce').val());
		}
		if($("#email_rifecommerce").val() !== ""){
			$('#emailrifecommerce_row .value').text($('#email_rifecommerce').val());
		}

		// LINGUA DOCUMENTAZIONE
		if($("#lingua_documentazione").val() !== ""){
			$('#linguadocumentazione_row .value').text($('#lingua_documentazione').val());
		}

        if($("#contatto_infoespositore").length && $("#contatto_infoespositore option:selected").val() !== ""){
            $('#contattoinfoespositore_row .value').text($('#contatto_infoespositore option:selected').val());
        } else {
        	$('#contattoinfoespositore_row').hide();
		}

		// ATTIVITA
		if($("#attivita_azienda").val() !== "") {
			var text= $('#attivita_azienda option:selected').text();
			if($("#attivita_azienda").val() === '5') {
				var areaValue= $('#area_import_export option:selected').val();
				var area= $('#area_import_export option:selected').text();
				var nazione= $('#nazione_europea option:selected').text();
				var paeseMondiale= $('#paese_del_mondo option:selected').text();
				if(areaValue!=='') {
					if(areaValue=='Europa') {
						text+= ': '+ area + ' - ' + nazione;
					}else{
						text+= ': '+ area + ' - ' + paeseMondiale;
					}
				}
			}
			$('#attivitaazienda_row .value').text(text);
		}
		if($("#attivita_svolta").val() !== "") {
            $('#attivitasvolta_row .value').text(_vars.attivitaSvolte[$('input[name=attivita_svolta_id]').val()]);
        }

		if($('#attivita_svolta').val() == 1 || $('#attivita_svolta').val() == 4 || $('#attivita_svolta').val() == 14) {
			$('#biologico_row .value').text($('[name="biologico"]:checked').data('text'));
		} else {
			$('#biologico_row').addClass('hidden');
		}

        var descrizione_prodotti='';
        $('.descrizione_prodotti').each(
            function(pos,item){
                var desc= $(item).val();
                if(desc!=='') {
                    if(descrizione_prodotti!=''){
                        descrizione_prodotti+= ' | ';
                    }
                    descrizione_prodotti+= desc;
                }
            }
        );
		if(descrizione_prodotti !== ""){
            $('#prodotti_row .value').text(descrizione_prodotti);
            $('#descrizione_prodotti').val(descrizione_prodotti);
		}

		if($("#merceologia_group #root .Path").length > 0){
			var _merci= "<div style='display: block;'>";
			$("#merceologia_group #root .Path").each(function(){
				var merceologia= $(this).html();
				var merceArrayReduced = merceologia.split(" / ").reduce( function ( accumulator, currentValue, index, array ) {
					if(index < array.length-1){
						return accumulator + currentValue + ' / '
					}else{
						return accumulator + '<strong>' + currentValue + '</strong>'
					}

				}, '');
				var extraDesc= $('.ExtraInfos input[type="text"]', $(this).parent().parent());
				var category = '<span>'+merceArrayReduced+(extraDesc.length>0?' ('+extraDesc.val()+')':'')+'</span>'

				var merccode = $(this).siblings('input[type="hidden"]').val()
				var items = $('input[name="merceologie_desc[' + merccode + ']"]').val().split('|')

				var products = '<ul>';
				items.forEach(function (item) {
					products += '<li>' + item + '</li>'
				});
				products += '</ul>';

				_merci+= '<div class="row_l"><label class="checkbox inline">' + category + products + '</label></div>';
			});
            _merci+= "</div>";
			$('#merceologia_row .value').html(_merci);
		}

		if ($('[name="attivita_istituzioni[]"]:checked').length) {
			var checkedAttIstituzionali = $('[name="attivita_istituzioni[]"]:checked');
			var _attIstitutzionale = "<div style='display: block;'>";
			checkedAttIstituzionali.each(function(i) {
				_attIstitutzionale +=
				"<div class='row_l'>" +
					"<label class='checkbox inline'>" +
						"<span>" + $(this).val() + "</span>" +
					"</label>" +
				"</div>";
			});
			_attIstitutzionale += "</div>";
			$('#merceologia_row .value').html(_attIstitutzionale);
		}

		//visibilitá app
		$('#visibilitacatalogo_row .value').addClass('hidden');
       	switch(Number($("input[name=apparire_a_catalogo]:checked").val())) {
			case 0:
				$('#visibilitacatalogo_row__no').removeClass('hidden');
				break;
			case 1:
                $('#visibilitacatalogo_row__yes').removeClass('hidden');
				break;
		}

		//la tua storia - riepilogo
		$("#storiaattivita_row .value").html(
			$("#storiaattivita").val()
		)

		// --- ---  -- #taglist_summary_row da riempire
		console.log('ciaone')
		var _tags = "<div style='display: block;'>";
		$("#merceologia_group .TagDetails .TagDetails_Element input[type=checkbox][data-text-value][data-category]:checked").each(function( index ) {
			var tagName = $(this).data('text-value');
			var tagCategory = $(this).data('category');
			var valueToAdd = tagCategory + ' - <strong>' + tagName + '</strong>';
			_tags+= '<div class="row_l"><label class="checkbox inline"><span>' + valueToAdd + '</span></label></div>';
		});
		_tags+= "</div>";
		$('#taglist_summary_row .value').html(_tags);
		console.log('ciaone end')

		//BRANDS - recap
		var valueToAdd = $("#merceologia_group .Brands__description input[type=text]").val();
		if(valueToAdd){
			var _brands = "<div style='display: block;'>";
			_brands+= '<div class="row_l"><label class="checkbox inline"><span>' + valueToAdd + '</span></label></div>';
			_brands+= "</div>";
			$('#brand_summary_row .value').html(_brands);
		}else{
			$('#brand_summary_row').hide();
		}

		// AREA STAND
		if($("#mqrichiesti_stand").val() !== ""){
			$('#mqrichiestistand_row .value').text($('#mqrichiesti_stand').val());
		}
		if($("#latiliberi_stand").val() !== ""){
			$('#latiliberistand_row .value').text($('#latiliberi_stand').val());
		}
		if($("#naziende_stand").val() !== ""){
			$('#naziendestand_row .value').text($('#naziende_stand').val());
		}

        if($("#naziende_stand").val() == "1"){
          $('#naziende_row').removeClass('hidden');
        }else{
          $('#cappastand_row').addClass('hidden');
        }



        if($("#cappastand").val() == "1"){
          $('#naziendestand_row .value').text($('#naziende_stand').val());
        }



		// PAGAMENTO
		if($("#importo_imponibile .value").val() !== ""){
			$('#imponibile_row .value').text($("#importo_imponibile").val());
		}
		if($("#importo_iva .value").val() !== ""){
			$('#iva_row .value').text($("#importo_iva").val());
		}
		if($("#importo_totale .value").val() !== ""){
			$('#totale_row .value').text($("#importo_totale").val());
		}
		if($("#modalita_pagamento").val() !== ""){
			if ($('#modalita_pagamento option:selected').val() === "full") {
				$('#modalitapagamento_row .value').text("100%");
			} else {
				$('#modalitapagamento_row .value').text($('#modalita_pagamento option:selected').text());
			}
		}
		if($("#acconto_pagamento").val() !== ""){
			$('#acconto_row .value').text($('#acconto_pagamento').val());
		}
		if($("#modalita_pagamento").val() == "rateSdd" || $("#modalita_pagamento").val() == "rateCarta" ){
			$('#rate_group').show();
			$('#rate_group .complex-table').html($('#tabella_rate')[0].innerHTML);
		}else{
			$('#rate_group .complex-table').html('');
			$('#rate_group').hide();
		}
		if($("#forma_pagamento").val() !== ""){
			$('#formapagamento_row .value').text($('#forma_pagamento option:selected').text());
		}

		// SERVIZI AGGIUNTIVI
		if($("[name='raccordoidrico_stand']:checked").val() == "1"){
			$('#idricostand_row').removeClass('hidden');
		}else{
			$('#idricostand_row').addClass('hidden');
		}
		if($("[name='raccordoelettrico_stand']:checked").val() == "1"){
			$('#elettricostand_row').removeClass('hidden');
		}else{
			$('#elettricostand_row').addClass('hidden');
		}
		if($("[name='cappa_stand']:checked").val() == "1"){
			$('#cappastand_row').removeClass('hidden');
		}else{
			$('#cappastand_row').addClass('hidden');
		}
		if($("[name='accettazione_trasmissioni_sonore']:checked").val() == "1"){
			$('#trasmissionisonore_row').removeClass('hidden');
		}else{
			$('#trasmissionisonore_row').addClass('hidden');
		}

		if($("[name='raccordoidrico_stand']:checked").val() == "1" || $("[name='raccordoelettrico_stand']:checked").val() == "1" || $("[name='cappa_stand']:checked").val() == "1"){
			$('#servizi-title').removeClass('hidden');
		}else{
			$('#servizi-title').addClass('hidden');
		}

		// DATI CATALOGO

		if($("#insegna_azienda_catalogo").val() != ""){
			$('#insegna_row .value').text($('#insegna_azienda_catalogo').val());
		}

		// DATI CORRISPONDENZA
		var regexCountry = RegExp('Ita.+');
		var nazAzienda = $("#nazione_azienda").val();

        if(regexCountry.test(nazAzienda)){
		    $("#emailcorrisp_row").hide();
		    $("#emailpeccorrisp_row").show();
			$('#emailpeccorrisp_row .value').text($('#email_spedizione_fatture_pec').val());
            $("#sdicorrisp_row").show();
			$('#sdicorrisp_row .value').text($('#codice_destinatario_sdi').val());
		}else{
		    $("#emailpeccorrisp_row").hide();
            $("#sdicorrisp_row").hide();
		    $("#emailcorrisp_row").show();
            $('#emailcorrisp_row .value').text($('#email_spedizione_fatture').val());
		}
	}


	/* ----------------- do it public ---------------*/

	return self;

})(jQuery,Modernizr, document, window);
